import * as React from "react";
import * as styles from "../styles/common.module.css";
import { useGlobalContext } from '../context/globalVars';

const Footer = () => {

	const { pageUL, setPageUL } = useGlobalContext();

	return (
		<>
			<article className={!pageUL ? styles.stacks : styles.stacksUL}><span>このサイトは Gatsby 5.11.0 で制作されています</span></article>
			<footer className={!pageUL ? styles.footer_top : ''}>
				<small>Copyright ©2024 THETA</small>
			</footer>
		</>
	);
};

export default Footer;
