import React, { createContext, useState, useContext } from 'react';
import Fire from "../components/fire";
import Header from "../components/header"
import Footer from "../components/footer"
import ParticlesBG from '../components/particles';
import * as styles from "../styles/common.module.css";

const AppGlobal = createContext();

export function useGlobalContext() {
  return useContext(AppGlobal);
}

export function AppGlobalProvider({ children }) {

	const [domLoaded, setDomLoaded] = useState(false);
	const [pageUL, setPageUL] = useState(false);
	const [menuIn, setMenuIn] = useState(false);
	const [fireStatus, setFireStatus] = useState('');
	const [ignition, setIgnition] = useState(0);
	const [animationPosition, setAnimationPosition] = useState(0);

	const value = {
		domLoaded, setDomLoaded,
		pageUL, setPageUL,
		menuIn, setMenuIn,
		fireStatus, setFireStatus,
		ignition, setIgnition,
		animationPosition, setAnimationPosition,
	};

  return (
    <AppGlobal.Provider value={value}>
		<div className={`${styles.particles} ${(fireStatus == 'UNDER') ? styles.ParticlesBlue : ""}`}>
			{domLoaded ? <ParticlesBG /> : ""}
		</div>
		{domLoaded ? <Fire status={fireStatus} /> : ""}
		<Header/>
		{children}
		<Footer/>
	</AppGlobal.Provider>
  );
}