import * as React from "react";
import { useState } from 'react';
import { Link } from "gatsby";
import { useGlobalContext } from '../context/globalVars';
import * as styles from "../styles/common.module.css";
import "../styles/common.module.css";

const Header = () => {

	const { pageUL, setPageUL, menuIn, fireStatus, setFireStatus } = useGlobalContext();
	const [currentPage, setCurrentPage] = useState('');

	const closeNavi = () => {
		document.querySelector("#menuInput").checked = false;
	}

	return (
		<header className={menuIn ? styles.visible : ''}>
			<div className={styles.logo}>
				<Link to="/" onClick={() => {
					setCurrentPage('top');
					setPageUL(false);
					setFireStatus('SLOW_IN');
				}}>THETA</Link>
			</div>
			<nav>
				<input type="checkbox" id="menuInput" />
				<label htmlFor="menuInput" id="menuButton"><span><span></span></span></label>
				<ul id="mainNavi">
					<li key="1" className={(currentPage == 'about') ? styles.pageCurrent : ''}>
						<Link to="/about" onClick={() => {
							closeNavi();
							setCurrentPage('about');
							setPageUL(true);
							setFireStatus('UNDER');
						}}>About</Link>
					</li>
					<li key="2" className={(currentPage == 'skill') ? styles.pageCurrent : ''}>
						<Link to="/skill" onClick={() => {
							closeNavi();
							setCurrentPage('skill');
							setPageUL(true);
							setFireStatus('UNDER');
						}}>Skill</Link>
					</li>
					<li key="3" className={(currentPage == 'record') ? styles.pageCurrent : ''}>
						<Link to="/record" onClick={() => {
							closeNavi();
							setCurrentPage('record');
							setPageUL(true);
							setFireStatus('UNDER');
						}}>Track Record</Link>
					</li>
					<li key="4" className={(currentPage == 'contact') ? styles.pageCurrent : ''}>
						<Link to="/contact" onClick={() => {
							closeNavi();
							setCurrentPage('contact');
							setPageUL(true);
							setFireStatus('UNDER');
						}}>Contact</Link>
					</li>
					<li key="5" className={(currentPage == 'partner') ? styles.pageCurrent : ''}>
						<Link to="/partner" onClick={() => {
							closeNavi();
							setCurrentPage('partner');
							setPageUL(true);
							setFireStatus('UNDER');
						}}>Partner</Link>
					</li>
				</ul>
			</nav>
		</header>
	);
};

export default Header;
