import React, { useCallback } from 'react';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import * as styles from "../styles/common.module.css";

const ParticlesBG = () => {

	const particlesInit = useCallback(async (engine) => {
		// console.log(engine);
		await loadFull(engine);
	}, []);

	const particlesLoaded = useCallback(async (container) => {
		// await console.log(container);
	}, []);

	return (
		<Particles
			id={styles.PARTICLES}
			init={particlesInit}
			loaded={particlesLoaded}
			options={{
				fpsLimit: 60,
				particles: {
					number: {
						value: 0,
						density: {
							enable: true,
							value_area: 800,
						},
					},
					color: {
						value: "#ffee00",
						animation: {
							enable: true,
							speed: -30, //色変化
							sync: true,
						},
					},
					shape: {
						type: "circle",
					},
					opacity: {
						value: 1,
						random: true,
						animation: {
							enable: true,
							speed: 5,
							minimumValue: 0,
							sync: false,
						},
					},
					size: {
						value: 1,
						random: { enable: true, minimumValue: 3 },
						animation: {
							enable: false,
							speed: 20,
							minimumValue: 4,
							sync: false,
						},
					},
					life: {
						duration: {
							value: 3, //寿命
						},
						count: 1,
					},
					move: {
						angle: {
							value: 45,
							offset: 0,
						},
						enable: true,
						gravity: {
							enable: true,
							acceleration: -0.5,
						},
						speed: 8,	//速度
						direction: "top",
						random: false,
						straight: false,
						size: true,
						outModes: {
							default: "destroy",
							bottom: "none",
						},
						attract: {
							enable: false,
							distance: 300,
							rotate: {
								x: 600,
								y: 1200,
							},
						},
					},
				},
				interactivity: {
					detectsOn: "canvas",
					events: {
						resize: true,
					},
				},
				detectRetina: true,
				background: {
					color: "#000000",
				},
				emitters: {
					direction: "top",
					rate: {
						quantity: 5, //数
						delay: 0.01,
					},
					size: {
						width: 100,
						height: 10,
					},
					position: {
						x: 50,
						y: 100,
					},
				},
			}}
		/>
	);
};

export default ParticlesBG;
